import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
const Status = ({
  setCommunicationSave,
  isCommunicationSave,
  isOnlyTimeSlip,
  isOnlyDocs,
  showSuccess,
}) => {
  useEffect(() => {
    SetShowSuccess(showSuccess);
  }, [showSuccess]);

  const [IsSuccess, SetShowSuccess] = useState(true);
  const handleClose = () => {
    SetShowSuccess(false);
  };
  return (
    <div className="justify-center align-middle">
      {IsSuccess && <img src="../../assets/success.jpg" />}
      {IsSuccess && (
        <div className=" flex justify-center align-middle">
          <button
            type="button"
            className="px-6 py-2 border rounded-lg hover:bg-gray-50"
            onClick={handleClose}
          >
            OK
          </button>
        </div>
      )}
    </div>
  );
};

export default Status;
