import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const Loader: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography ><CircularProgress size={70} /></Typography>
    </Box>
  );
};

export default Loader;
