// src/components/MessageAlert.tsx
import React, { useState, useEffect } from 'react';

interface MessageAlertProps {
    message: string;
    type?: 'success' | 'error' | 'info' | 'warning';
}

const MessageAlert: React.FC<MessageAlertProps> = ({ message, type = 'info' }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 10000); //auto-hide after 3 seconds

        return () => clearTimeout(timer);
    }, [message]);

    if (!isVisible) return null;

    const getAlertColor = () => {
        switch (type) {
            case 'success':
                return 'text-green bold';
            case 'error':
                return 'bg-red-500 text-white';
            case 'warning':
                return 'bg-yellow-500 text-black';
            default:
                return 'bg-blue-500 text-white';
        }
    };
    // fixed top-4 right-4 px-4 py-2
    return (
        <div style={{marginTop:'250px',marginLeft:"250px"}} className={`text-xl ${getAlertColor()}`}>
            {message}
        </div>
    );
};

export default MessageAlert;
