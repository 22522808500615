import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, ActivityTypes} from "../../config/config";
let AccessToken = localStorage.getItem('AccessToken1');
export const fetchAllActivityCode = createAsyncThunk(
  'activityCode/fetchAllActivityCode',
  async (AccessTokenParam:any, { rejectWithValue }) => {
    // debugger//
    //`Utilities/ActivityTypes`
    try {
      if(AccessToken ==null){
				AccessToken = AccessTokenParam;
			  }
      const response = await axios.get(API_BASE_URL + ActivityTypes, {
        headers: {
          Authorization : `Bearer ` + AccessToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'mode': 'outlook'
        },
      });
    // console.log("All-activityCode", response.data);
     
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const activityCodeSlice = createSlice({
  name: 'activityCode',
  initialState: {
    activityCode: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllActivityCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllActivityCode.fulfilled, (state, action) => {
        state.loading = false;
        state.activityCode = action.payload;
      })
      .addCase(fetchAllActivityCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default activityCodeSlice.reducer;
