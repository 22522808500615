import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, Page, Size} from "../../config/config";
let AccessToken = localStorage.getItem('AccessToken1');
let CurrentPage = Number(Page);
export const fetchAllContacts = createAsyncThunk(
  'contact/fetchAllContacts',
  async (Param:any, { rejectWithValue }) => {
    try {
			if(AccessToken ==null){
				AccessToken = Param.AccessTokenParam;
			  }
			  CurrentPage = Param.NextPage
      //console.log("fetchAllContacts " + AccessToken);
      const response = await axios.get(API_BASE_URL + `Contacts/GetContacts/` + CurrentPage + "/" +Size, {
        headers: {
            Authorization : `Bearer ` + AccessToken,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'mode': 'outlook'
        },
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: [],
    loading: false,
    error: null,
    page:1,
		pageCount:1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllContacts.fulfilled, (state, action) => {
        state.loading = false;
        
        let carray =(state.contacts !=null) ? state.contacts : [];
				Array.prototype.push.apply(carray, action.payload.data);  

       // state.contacts = action.payload.data;
        state.pageCount = action.payload.pageCount;
				state.page = action.payload.page;
      })
      .addCase(fetchAllContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default contactSlice.reducer;
