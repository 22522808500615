// src/context/AlertContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import MessageAlert from './MessageAlert';


interface AlertContextType {
    showAlert: (message: string, type?: 'success' | 'error' | 'info' | 'warning') => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

interface AlertProviderProps {
    children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [alert, setAlert] = useState<{ message: string; type?: 'success' | 'error' | 'info' | 'warning' } | null>(null);

    const showAlert = (message: string, type: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlert({ message, type });
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            {alert && <MessageAlert message={alert.message} type={alert.type} />}
        </AlertContext.Provider>
    );
};
