
const API_BASE_URL= "#{API_BASE_URL}#";

const domain ="#{domain}#"
const audience = "#{audience}#";
const clientId = "#{clientId}#";
const scope = "openid profile email";

const GetAllMatter= "Matter/Outlook/GetAllMatterList/";
const ActivityTypes = "Utilities/ActivityTypes";
const GetServiceTypes = "Utilities/GetServiceTypes";
const GetTags = "Utilities/GetTags";
const TaskType = "Utilities/TaskType";
const CreateCommunicationsform = "Communications/Outlook/Createform";

const CreateTimeslip = "TimeSlip/Outlook/CreateNewTimeSlip";
const CreateClarraDocs = "ClarraDocs/Create";
const CreateClarraDocsform ="ClarraDocs/Outlook/Create";
const CreateClarraDocsBody = "ClarraDocs/Create/zapierbody";
const GetPermission= "Communications/GetPermission/";
const GetCommunicationByExternalId  = "Communications/Outlook/GetDetailExternal";

const Page="0";
const Size="500";
export {
    API_BASE_URL,
    domain,
    audience,
    scope,
    clientId,
    GetAllMatter,
    ActivityTypes,
    GetServiceTypes,
    GetTags,
    TaskType,
    CreateTimeslip,
    CreateClarraDocs,
    Page,
    Size,
    GetPermission,
    CreateCommunicationsform,
    CreateClarraDocsform,
    CreateClarraDocsBody,
    GetCommunicationByExternalId
  };
