import * as React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./LoginComponent.css";
import BaseComponent from "../BaseComponent";
import { audience, scope } from "../../config/config";

let AccessToken = null;

const LoginComponent: React.FC = () => {
	const {
		loginWithPopup,
		//loginWithRedirect,
		getIdTokenClaims,
		getAccessTokenSilently,
		logout,
		user,
		isAuthenticated,
	} = useAuth0();

	const [AccessApikey, setapikey] = useState(null);
	const [GetAuthenticated, setAuthenticated] = useState(false);
	
	const handleLogin = async () => {
		try {
			
			// var origin = window.location.origin;
			// const authurl = `https://loginqa.clarra.com/authorize?client_id=4XEcTDvBRWpYUoLjDdREO3IycFqFlhvq&response_type=code&redirect_uri=` + origin + `&scope=openid%20profile%20email&audience=https://clarraqa/external/api`
  			// const features = 'width=350,height=500,scrollbars=yes,resizable=yes';
  			// window.open(authurl, '_blank', features);
  
			//window.open("https://clarra.com/", "_NEW");
			// if(false){
			 //	await loginWithPopup();
			// 	loginWithPopup,
	
			// }
			//if(false) {
				await loginWithPopup({
						authorizationParams : {
						scope: "openid profile email",
						redirect_uri: window.location.origin,
						audience: audience //'https://clarraqa/external/api'
					}
				 });

			// 	 if(false) {
			// 	 await loginWithRedirect({
			// 		authorizationParams : {
			// 		scope: "openid profile email",
			// 		redirect_uri: window.location.origin,
			// 		audience: audience //'https://clarraqa/external/api'
			// 	}
			//  });
			// }
		//	}
		//await loginWithRedirect();
			
		// await loginWithRedirect({
		// 	authorizationParams : {
		// 		audience : 'https://localhost:3000/callback',
		// 		redirect_uri: window.location.origin,
		// 	}
        // });

			
			
			const token = await getAccessToken();
			if(token !=null)
				setAuthenticated(true);
			//AccessToken = token;
// 			if(token){
// 				localStorage.setItem('AccessToken', token);
// 				setAuthenticated(true);
// 				//console.log("User :" + user.name);
// //				console.log("After Login Token :" + token);
// 			}	
		} catch (error) {
			console.error("Login failed:", error);
		}
	};

	const handleLogout = () => {
		localStorage.removeItem('AccessToken');
		localStorage.removeItem('AccessToken1');
		logout({
			logoutParams: { returnTo: window.location.origin },
		});
	};
	const getAccessToken = async () => {
		//debugger;
		let token;
		try {
			token = await getAccessTokenSilently();
			if(token !=null) {
				//localStorage.clear();
				localStorage.removeItem('AccessToken1');
				localStorage.setItem('AccessToken1', token);
			  //localStorage.setItem('AccessToken', token);
				console.log("Login getAccessToken " + token)
				setAuthenticated(true);
			}
			
			//debugger;
			//console.log("T1 " + token);
		} catch (exception) {
			//debugger;
		
		}
		return token;
	};

	const LoginwithAPIKey = () => {
		if (AccessApikey.trim().length <= 0) {
			console.error("Invalid API Key");
			setapikey("");
		} else {
			setapikey(AccessApikey);
		}
		return <BaseComponent />;
	};

	const NavigateToBase = () => {
		return <BaseComponent />;
	}
	useEffect(() => {
		// Handle Auth0 callback by parsing the token after login
		//debugger;
		if (isAuthenticated) {
			// if(AccessToken !=null) {
			// 	NavigateToBase();
			// }
			// else {
			// 	getAccessToken();
			// }
			if (user != null) {
				console.log("User Name - " + user.name);
			}
		} else {
			//debugger;
			// loginWithRedirect();
			console.log("isAuthenticated" + isAuthenticated);
		}
	}, [isAuthenticated]);

	// if (isAuthenticated) {
	// 	// Render BaseComponent after successful login
	// 	 NavigateToBase();
	// 	//return <BaseComponent />;
	// }
	const features = [];
	const features1 = [
		{
		  title: "Clarra for Communications",
		  description:
			"Streamlined legal interactions and enhanced firm collaboration with intelligent tracking of your Outlook inbox in Clarra communication",
		},
		{
		  title: "Timekeeping",
		  description:
			"Accurate legal timekeeping for billing accuracy",
		},
		{
		  title: "Document Management",
		  description:
			"Streamlined document organization and enhanced security for legal collaboration with ClarraDocs in Clarra",
		},
	  ];
	  const openClarraSite = () => {
		// window.open(
		// 	"https://clarra.com/",
		// 	"ClarraPopup",
		// 	"width=800,height=600,left=200,top=100"
		//   );
		window.open("https://clarra.com/", "_NEW");
	  };
	return (
		<div className="login-container">
			<div className="login-box">
				{GetAuthenticated ? (
					<BaseComponent />
				) : (
					<>
					   <div style={{ textAlign: 'center', fontSize: '24px', color: '#333' }}>
					   	<img  src='assets/icon-80.png' alt="Clarra Logo"  className="logo" />
					   </div>
						
						<h1 style={{ fontSize: '16px', color: '#4a4a4a' }}>Welcome to Clarra</h1>
						<p></p>
						<h1 style={{ fontSize: '14px', color: '#4a4a4a' }}>Connect Microsoft Outlook with Clarra</h1>
						{/* <h2>log your emails</h2> */}
						{/* <h2>Clarra </h2> */}
						{/* <p><img src='assets/Clarra_Logo.svg' alt="Clarra Logo" className="logo" /></p> */}
						{/* <input
                    type="email"
                    placeholder="Email address*"
                    className="input-field"
                />
                <input
                    type="password"
                    placeholder="Password*"
                    className="input-field"
                />
                <a href="#" className="forgot-password">Forgot password?</a> */}
						<button onClick={handleLogin} className="login-button">
							Sign in
						</button>

						
						<div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
						{/* <h1 style={{ textAlign: 'center', fontSize: '16px', color: '#333' }}>
							Bring the power of Clarra to your inbox through Clarra Outlook Add-in. </h1> */}
      {/* <h1 style={{ textAlign: 'center', fontSize: '24px', color: '#333' }}>
        Clarra Outlook Addin Features Overview
      </h1> */}
      
      {features.map((feature, index) => (
        <div
          key={index}
          style={{
            margin: '20px 0',
            padding: '15px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <h2 style={{ fontSize: '16px', color: '#4a4a4a' }}>{feature.title}</h2>
          <p style={{ fontSize: '14px', color: '#555' }}>{feature.description}</p>
        </div>
      ))}
	  {/* <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <button
          onClick={openClarraSite}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: '#28b898',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Visit Clarra
        </button>
      </div> */}
    </div>
						{/* <p><header>Clarra Communications</header></p>
						<p>Track your Outlook inbox in Clarra Communication.</p>
						<p><header>TimeSlip</header></p>
						<p>Update Timeslip in Clarra Communication.</p>
						<p><header>ClarraDocs</header></p> */}
						{/* <p>Protect your email attachments ClarraDocs.</p> */}
								
						{/* <div>
						<p></p>
						<p>OR</p>
						<p>Clarra API Key</p>
						<input
							type="input"
							placeholder="API Key"
							value={AccessApikey}
							onChange={(e) => setapikey(e.target.value)}
							className="input-field"
						/>
						<button
							onClick={LoginwithAPIKey}
							className="login-button"
						>
							Connect with API Key
						</button>
						</div> */}
						{/* <button onClick={handleLogout} className="login-button">
							Log out
						</button> */}
					</>
				)}
			</div>
		</div>
	);
};

export default LoginComponent;
