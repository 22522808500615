import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL} from "../../config/config";
let AccessToken = localStorage.getItem('AccessToken1');
export const fetchAllUsers = createAsyncThunk(
  'user/fetchAllUsers',
  async (AccessTokenParam:any, { rejectWithValue }) => {
    try 
     {
      //console.log("fetchAllTags " + AccessToken);
      if(AccessToken ==null){
        AccessToken = AccessTokenParam;
      }
      const response = await axios.get(API_BASE_URL + `Utilities/Users`, {
        headers: {
            Authorization : `Bearer ` + AccessToken,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'mode': 'outlook'
        },
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
      //  debugger;
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
