import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import "./Permission.css";

const Permission: React.FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("AccessToken1");
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1 style={{ fontSize: "16px", color: "#4a4a4a" }}></h1>
        <p></p>
        <h1 style={{ fontSize: "14px", color: "#4a4a4a" }}></h1>

        <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
          <h2 style={{ textAlign: "center", fontSize: "16px", color: "#333" }}>
            Communication
          </h2>

          <h1 style={{ textAlign: "center", fontSize: "16px", color: "#333" }}>
            Permission Denied{" "}
          </h1>
        </div>

        <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
          <h2 style={{ textAlign: "center", fontSize: "16px", color: "#333" }}>
            This email is allready logged.
          </h2>
        </div>
        <button onClick={handleLogout} className="login-button">
          Sign out
        </button>
      </div>
    </div>
  );
};

export default Permission;
