import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {API_BASE_URL, GetAllMatter, Page, Size} from "../../config/config";
import {useAuth0} from '@auth0/auth0-react';
import { debug } from "webpack";

// Async thunk to fetch all matters
let AccessToken = localStorage.getItem('AccessToken1');
let CurrentPage = Number(Page);
export const fetchAllMatters = createAsyncThunk(
	"matters/fetchAllMatters",
	async (Param:any, { rejectWithValue }) => {
		//debugger;
		try {
			if(AccessToken ==null){
				AccessToken = Param.AccessTokenParam;
			  }
			  CurrentPage = Param.NextPage
		 //  console.log("fetchAllMatters " + AccessToken);
		
			const response = await axios.get(API_BASE_URL + GetAllMatter + CurrentPage + "/" +Size,
				{
					headers: {
						  Authorization : `Bearer ` + AccessToken,
						'Content-Type': 'application/json',
						Accept: 'application/json',
						'mode': 'outlook'
					},	
				}
			);
			return response.data; // Return the fetched data
		} catch (error) {
			return rejectWithValue(error.response.data); // Return error message
		}
	}
);

const matterSlice = createSlice({
	name: "matters",
	initialState: {
		matters: [],
		loading: false,
		error: null,
		page:1,
		pageCount:1,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllMatters.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchAllMatters.fulfilled, (state, action) => {

				state.loading = false;
				
				let marray =(state.matters !=null) ? state.matters : [];
				Array.prototype.push.apply(marray, action.payload.data);  
				state.matters = marray;
				//if(action.payload.pageCount > 0 && ){
				state.pageCount = action.payload.pageCount;
				state.page = action.payload.page;
				 //}
				// debugger;
				// if( action.payload.data.length > 0){
				// 	CurrentPage = CurrentPage +1;
				// 	fetchAllMatters(null);
				// }
			})
			.addCase(fetchAllMatters.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export default matterSlice.reducer;
