// import *  as React from 'react';
//import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch, RootState } from './Redux/store';
// // import Header from './Header';
// // import Progress from './Progress';
// import OfficeAddinMessageBar from './OfficeAddinMessageBar';
// import RoutesComponent from './Routes/RoutesComponent';
// // import Profile from './Profile';
// // import { Box } from '@mui/material';
// // import { logout } from './Redux/authSlice';
// // import { useDispatch, useSelector } from 'react-redux';
// // import { AppDispatch, RootState } from './Redux/store';
// // import { logout } from './Redux/authSlice';
// // import { logoutFromO365, signInO365 } from '../../utilities/office-apis-helpers';

// interface AppProps {
//     title: string;
//     isOfficeInitialized: boolean;
// }
export interface AppState {
	authStatus?: string;
	fileFetch?: string;
	headerMessage?: string;
	errorMessage?: string;
}
// // import { PublicClientApplication } from "@azure/msal-browser";
// // import { msalConfig } from './Authentication/authConfig';

// // const msalInstance = new PublicClientApplication(msalConfig);
// const App: React.FC<AppProps> = ({
//     //  title,
//       isOfficeInitialized }) => {
// // const App: React.FC<AppProps> = ({ title, isOfficeInitialized }) => {
//     // const headerMessage = '';
//     const [errorMessage, setErrorMessage] = React.useState<string>('');

//     // const dispatch = useDispatch<AppDispatch>();
//     // const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

//     // const handleLogout = () => {
//     //     dispatch(logout());
//     // };

//     if (!isOfficeInitialized) {
//         return (
//             // <Progress
//             //     title={title}
//             //     logo='assets/Clarra_Logo.svg'
//             //     message='Please sideload your add-in to see app body.'
//             // />
//             <RoutesComponent />
//         );
//     }

//     return (
//         <div>
//             {errorMessage && (
//                 <OfficeAddinMessageBar
//                     onDismiss={() => setErrorMessage('')}
//                     message={errorMessage + ' '}
//                 />
//             )}

//             <div>
//                 {/* {isAuthenticated && (
//                     <Header
//                         logo='assets/Clarra_Logo.svg'
//                         title={title}
//                         message={headerMessage}
//                     />
//                 )} */}
//               {/* <Box sx={{marginLeft:32}}>
//               <Profile />
//               </Box> */}
//                 <RoutesComponent />
//                 {/* {isAuthenticated && (<button onClick={handleLogout}>LogOut</button>   )} */}
//                 {/* <h1>hello !</h1> */}
//             </div>
//         </div>
//     );
// };

// export default App;
import * as React from "react";
import { useState } from "react";
import OfficeAddinMessageBar from "./OfficeAddinMessageBar";

import { useAuth0 } from "@auth0/auth0-react";
import BaseComponent from "./BaseComponent"; // Component to render after login
//import Login from "./Authentication/Login";
import LoginComponent from "./Authentication/LoginComponents";
import { AlertProvider } from "./MessageAlert/AlertContext";
import { AppDispatch } from "./Redux/store";
import { useDispatch } from 'react-redux';


interface AppProps {
	title: string;
	isOfficeInitialized: boolean;
}
const App: React.FC<AppProps> = () => {
	const { isAuthenticated } = useAuth0();
	const dispatch = useDispatch<AppDispatch>();
	React.useEffect(() => {
	//	console.log("From App ");
		console.log("isAuthenticated APP " + isAuthenticated);
		
	}, [isAuthenticated]);
	//const [setIsAuthenticated] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	//let isAuthenticated = false;
	// const token = localStorage.getItem('access_token');
	// if (token) {
	//    // setIsAuthenticated(true); // User is authenticated if token exists
	//    isAuthenticated = true;
	// }
	// useEffect(() => {
	//     // Check if the user is already authenticated (token exists in localStorage)
	//     const token = localStorage.getItem('access_token');
	//     if (token) {
	//        // setIsAuthenticated(true); // User is authenticated if token exists
	//         isAuthenticated = true;
	//     }
	// }, []);

	// if (!isOfficeInitialized) {
	//     return <div>Loading Office Add-in...</div>;
	// }

	return (
		
		<div>
			<AlertProvider>
			{errorMessage && (
				<OfficeAddinMessageBar
					onDismiss={() => setErrorMessage("")}
					message={errorMessage}
				/>
			)}
			{/* <LoginComponent /> */}
			{isAuthenticated ? <BaseComponent /> : <LoginComponent />}
			</AlertProvider>
		</div>
	);
};

export default App;
