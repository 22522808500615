import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL,GetTags} from "../../config/config";
let AccessToken = localStorage.getItem('AccessToken1');
export const fetchAllTags = createAsyncThunk(
  'tags/fetchAllTags',
  async (AccessTokenParam:any, { rejectWithValue }) => {
		try {
		 // console.log("fetchAllTags " + AccessToken);
      if(AccessToken ==null){
        AccessToken = AccessTokenParam;
      }
      const response = await axios.get(API_BASE_URL + GetTags , {
        headers: {
          Authorization : `Bearer ` + AccessToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'mode': 'outlook'
        },
      });
      return response.data; // Return the fetched data
    } catch (error) {
      return rejectWithValue(error.response.data); // Return error message
    }
  }
);

const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    tags: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = action.payload;
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default tagsSlice.reducer;
