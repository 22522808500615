import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, GetTags} from "../../config/config";
var AccessToken = localStorage.getItem('AccessToken1');
export const fetchAllstatus = createAsyncThunk(
  'status/fetchAllstatus',
  async (AccessTokenParam:any, { rejectWithValue }) => {
    try {
     // console.log("fetchAllTags " + AccessToken);
      if(AccessToken ==null){
        AccessToken = AccessTokenParam;
      }
      const response = await axios.get(API_BASE_URL + GetTags , {
        headers: {
          Authorization : `Bearer ` + AccessToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'mode': 'outlook'
        },
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const statusSlice = createSlice({
  name: 'status',
  initialState: {
    status: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllstatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllstatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
      })
      .addCase(fetchAllstatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default statusSlice.reducer;
