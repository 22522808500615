import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";
import { FormControl } from "@mui/material";
import axios from "axios";
import { GetcommunicationDetail } from "../Redux/communicationDetailSlice";
import { fetchAllMatters } from "../Redux/matterSlice";
import {API_BASE_URL, CreateCommunicationsform} from "../../config/config";
import { fetchAllContacts } from "../Redux/contactSlice";



const EmailLoggingForm = ({
  onSubmit,
  formData,
  setFormData,
  SetEmailItem,
  Reset,
}: {
  setOnlyTimeSlip: any;
  createTimeslip: any;
  setOnlyDocs: any;
  createClarraDocs: any;
  setCommunicationSave: any;
  onSubmit: any;
  formData: any;
  setFormData: any;
  SetEmailItem: any;
  Reset: any;
}) => {
	localStorage.setItem("API_BASE_URL",API_BASE_URL);
	localStorage.setItem("CreateCommunicationsform",CreateCommunicationsform);
  const dispatch = useDispatch<AppDispatch>();
  const matters = useSelector((state: RootState) => state.matters.matters);
  const mattersPageCount = useSelector((state: RootState) => state.matters.pageCount);
  const mattersNextPage = useSelector((state: RootState) => state.matters.page);
  
  const tags = useSelector((state: RootState) => state.tags.tags);
  const contacts = useSelector((state: RootState) => state.contact.contacts);
  
  const contactsPageCount = useSelector((state: RootState) => state.contact.pageCount);
  const contactsNextPage = useSelector((state: RootState) => state.contact.page);
  const communicationDetail = useSelector(
    (state: RootState) => state.communicationDetail.communicationDetail
  );
  const Detailloading = useSelector(
    (state: RootState) => state.communicationDetail.loading
  );

  let emailOptions = contacts
    .flatMap((item) => item.contactEmails)
    .filter((emailObj) => emailObj && emailObj.email)
    .map((emailObj) => ({ value: emailObj.email, label: emailObj.email }));

  // let Contacts = contacts
  //   .flatMap((item) => item.contactEmails)
  //   .filter((emailObj) => emailObj && emailObj.email)
  //   .map((emailObj) => ({ value: emailObj.email, label: item.firstName + " " + item.lastName  }));

  const [mailItem, setMailItem] = useState({
    subject: "",
    body: "",
    attachments: null,
    sender: "",
    senderDisplayName: "",
    recipents: [],
    date: null,
    time: "",
    timezone: "",
    referenceid: "",
    conversationid: "",
  });
  const [emailbody, setMailBody] = useState("");
  const [IsReset, SetIsReset] = useState(true);

  const [files, SetFiles] = useState([]);
  const { logout, getAccessTokenSilently } = useAuth0();

//  Office.initialize = function (reason) {
//     $(document).ready(function () {
//         Office.context.mailbox.addHandlerAsync(
//             Office.EventType.ItemChanged,
//             ReadEmailItem
//         );
//     });
// };

  
   

  async function onMessageSend(event) {
    try {
      // Get the email item
      const item = Office.context.mailbox.item;
      // Extract email details
      const subject = item.subject;
      const toRecipients = item.to.map((recipient) => recipient.emailAddress);
      const body = await getBody(item);
      // Send email data to your server
      await sendToServer({
        subject,
        toRecipients,
        body,
      });

      // Complete the event (allow email to be sent)
      event.completed({ allowEvent: true });
    } catch (error) {
      console.error("Error in onMessageSend:", error);

      // Optionally block the email from being sent
      event.completed({ allowEvent: false });
    }
  }

  // Helper: Get email body
  function getBody(item) {
    return new Promise((resolve, reject) => {
      item.body.getAsync(Office.CoercionType.Text, (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        } else {
          reject(result.error);
        }
      });
    });
  }

  // Helper: Send data to server
  async function sendToServer(emailData) {
    console.log(emailData);
  }

  const handleAttachmentsCallback = (result) => {
    try {
      //debugger;
      console.log("handleAttachmentsCallback-01:-", result);
      // Identifies whether the attachment is a Base64-encoded string, .eml file, .icalendar file, or a URL.
      console.log("handleAttachmentsCallback-02:-", result.value);
      switch (result.value.format) {
        case Office.MailboxEnums.AttachmentContentFormat.Base64:
          // Handle file attachment.
          console.log("Attachment is a Base64-encoded string.");
          break;
        case Office.MailboxEnums.AttachmentContentFormat.Eml:
          // Handle email item attachment.
          console.log("Attachment is a message.");
          break;
        case Office.MailboxEnums.AttachmentContentFormat.ICalendar:
          // Handle .icalender attachment.
          console.log("Attachment is a calendar item.");
          break;
        case Office.MailboxEnums.AttachmentContentFormat.Url:
          // Handle cloud attachment.
          console.log("Attachment is a cloud attachment.");
          break;
        default:
        // Handle attachment formats that aren't supported.
      }
    } catch (error) {}
  };
  
  function base64ToBlob(base64, mimeType) {
    // Decode the Base64 string
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob with the specified MIME type
    return new Blob([byteArray], { type: mimeType });
  }

  function base64ToFile(base64, fileName, mimeType) {
    //const blob = base64ToBlob(base64, mimeType);
    const blob = new Blob(
      [
        new Uint8Array(
          atob(base64)
            .split("")
            .map((char) => char.charCodeAt(0))
        ),
      ],
      { type: "application/octet-stream" }
    );
    return blob;
  }
   
  function onItemChanged(eventArgs) {
    // Called when the user selects a different email item
    const item = Office.context.mailbox.item;
    setHasAttachment(false);
    if (item) {
      console.log("Item changed to:", item.subject);
  }
    ReadEmailItem();
  }

  const ReadEmailItem = () => {
 	  setIsEmailRead(true);
    
    Office.onReady((info) => {
      try {
        if (
          info.host === Office.HostType.Outlook ||
          info.platform === Office.PlatformType.OfficeOnline
        ) {
	        const item = Office.context.mailbox.item;
	  	     const userProfilr = Office.context.mailbox.userProfile;
	         let token = localStorage.getItem("AccessToken1");
          if (token == null) {
            let x = getAccessToken();
            token = x.toString();
          }
 		if(item.itemId){
		    setCompodeMode(false);
          dispatch(
            GetcommunicationDetail({
              externalID: item.itemId,
              AccessTokenPAram: token,
            })
          );
		 }
		 else  {
			setCompodeMode(true);
		 }
          const attachments =
            item.attachments != null
              ? item.attachments.filter((item) => !item.isInline)
              : null;

          if (attachments && attachments.length > 0) {
            setHasAttachment(true);
            SetEmailItem(item);
            const options = { asyncContext: { currentItem: item } };

            const fileAttachPromises = attachments.map((attachment) => {
              return new Promise((resolve, reject) => {
                console.log(attachment.id);
                item.getAttachmentContentAsync(attachment.id, (result) => {
                  if (result.status === Office.AsyncResultStatus.Succeeded) {
                    const { content, format } = result.value;
                    if (format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
                      try {
                        // Convert Base64 to Blob or File
                        let file = base64ToFile(content, attachment.name, null);
                        resolve({
                          blobfile: file,
                          filename: attachment.name,
                        });
                      } catch (error) {
                        reject(`Error converting Base64 to file: ${error}`);
                      }
                    } else {
                      reject(`Unsupported format: ${format}`);
                    }
                  } else {
                    reject(`Failed to get attachment content: ${result.error.message}`);
                  }
                });
              });
            });

              //debugger;
              // Wait for all attachments to be processed
              
              Promise.all(fileAttachPromises)
                .then((files) => {
                  console.log('All attachments processed:', files);
                  //debugger;
                   SetFiles(files);
                    if (localStorage.getItem("attachments") != null)
                      localStorage.removeItem("attachments");
                    localStorage.setItem(
                      "attachments",
                      JSON.stringify(files)
                    );
                  // Do something with the files array
                })
                .catch((error) => {
                  console.error(
                    `Failed to fetch attachments `,
                     error
                  );
                });
         
          }
      //debugger;

		  const EmaildateTime =(item.dateTimeCreated) ? item.dateTimeCreated : null;
		  const hours = (EmaildateTime) ? EmaildateTime.getHours() : "00";    // Hours (0-23)
		  const minutes = (EmaildateTime) ? EmaildateTime.getMinutes() : "00"; // Minutes (0-59)
		  const seconds = (EmaildateTime) ? EmaildateTime.getSeconds(): "00"; // Seconds (0-59)
		  //const date = currentDateTime; 
		  const time =  `${hours}:${minutes}:${seconds}`;  
//debugger;
          setMailItem({
            ...mailItem,
            attachments:
              attachments && attachments.length > 0 ? attachments : [],
            subject: item.subject,
            sender: (item.sender && item.sender.emailAddress) ? item.sender.emailAddress : userProfilr.emailAddress,
            senderDisplayName: (item.sender) ? item.sender.displayName : userProfilr.displayName,
            recipents: (item.to) ? item.to : [],
            date: (item.dateTimeCreated) ?item.dateTimeCreated : Date.now, 
            time: time,
            referenceid: item.itemId,
            conversationid: item.conversationId,
			    timezone : userProfilr.timeZone
          });
	     //debugger;
          item.body.getAsync(Office.CoercionType.Html, (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
              setMailBody(result.value);
            }
          }); 
        } else {
          // development
          setMailItem({
            ...mailItem,
            attachments: [],
            subject: "Testing Data",
            sender: "sender@outlook.com",
            senderDisplayName: "Sender Company",
            recipents: [
              { displayName: "rec test", emailAddress: "rec@outlook.com" },
            ],
            date: Date.UTC,
            time: "10:10:10",
            referenceid: "uniqueid100",
          });
          setMailBody("Body");
        }
      } catch (error) {
        console.error("Error initializing Office.js:", error);
      } finally {
      }
    });
  };



  const onCancel = () => {
    // logout();
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("AccessToken1");
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };
  const getAccessToken = async () => {
    let token;
    try {
      token = localStorage.getItem("AccessToken1");
      if (token == null) {
        token = await getAccessTokenSilently();
      }
      if (token != null) {
        localStorage.removeItem("AccessToken1");
        localStorage.setItem("AccessToken1", token);
        SetAccessToken(token);
      }
    } catch (exception) {}
    return token;
  };
  
  useEffect(() => {
	// const sampleMatters = matters.map((matter) => ({
	// 	value: matter.id,
	// 	label: matter.matter + " (" + matter.number + ")",
	//   }));
    if(mattersNextPage < mattersPageCount) {
		let Pagenum = mattersNextPage +1;
		const matterParam = {
			'AccessTokenParam' : AccessToken,
			'NextPage' : Pagenum
		  };
           dispatch(fetchAllMatters(matterParam));
	  }
    {
      const selectedMatterMemory = localStorage.getItem("SelectedMatter");
      if(selectedMatterMemory !=null) {
         const findMatter = sampleMatters.find(item=> item.label == selectedMatterMemory);
         if(findMatter !=null)
           setSelectedMatter(findMatter);	
      }
    }
  }, [matters]);

  useEffect(() => {
      if(contactsNextPage < contactsPageCount) {
      let Pagenum = contactsNextPage +1;
      const contactParam = {
        'AccessTokenParam' : AccessToken,
        'NextPage' : Pagenum
        };
             dispatch(fetchAllContacts(contactParam));
    }
    }, [contacts]);

  useEffect(() => {
    setEmailLogged(false);
    if (communicationDetail != null) {
      if (communicationDetail.id > 0) {
        console.log("This message allready logged");
        setEmailLogged(true);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        SetIsReset(false);
      }
    } else if (isEmailRead == false) {
      SetIsReset(true);
	  setIsEmailRead(true);
     // ReadEmailItem();
    }
  }, [communicationDetail]);

  useEffect(() => {
    const x = getAccessToken();
    if (mailItem.sender) {
      const objfrom = {
        label: mailItem.sender,
        value: mailItem.sender,
      };

      if (emailOptions && emailOptions.length > 0) {
        if (
          emailOptions.find((item) => item.value == mailItem.sender) == null
        ) {
          emailOptions.push(objfrom);
        }
      }
      if (objfrom.value != null && objfrom.value != "")
        setSelectedFrom(objfrom);
    }
    if (mailItem && mailItem.recipents && mailItem.recipents.length > 0) {
      mailItem.recipents.forEach((element) => {
        if (emailOptions && emailOptions.length > 0) {
          if (
            emailOptions.find((item) => item.value == element.emailAddress) ==
            null
          ) {
            emailOptions.push({
              label: element.emailAddress,
              value: element.emailAddress,
            });
          }
        }
      });
      setSelectedTo(
        mailItem.recipents.map((item) => {
          return { label: item.emailAddress, value: item.emailAddress };
        })
      );
    }
  }, [dispatch, mailItem, emailbody]);

  const onSaveform = () => {
    const Mode: any = {
      cm: formData.createCommunication,
      tm: formData.createTimeslip,
      dm: formData.createClarraDocs,
    };
    const isValid = validateForm(Mode);

    if (isValid) {
      mailItem.attachments = files;
     // localStorage.setItem("SelectedMatter", selectedMatter);
      onSubmit(
        {
          ...formData,
          mailItem: mailItem,
          mailbody: emailbody,
          matter: selectedMatter,
          tag: selectedTags,
          Token: AccessToken,
        },
        Mode
      );
      setIsEmailRead(false);
    }
  };

  // Sample data for dropdowns
  
  let sampleMatters = matters.map((matter) => ({
    value: matter.id,
    label: matter.matter + " (" + matter.number + ")",
  }));

  //setSelectedMatter(value);
  
  
  const sampleTags = tags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  const handleFromChange = (selectedOption: any) => {
    setSelectedFrom(selectedOption);
  };
  const handleToChange = (selectedOptions: any) => {
    setSelectedTo(selectedOptions);
    //debugger;
    const SelectedTo = (selectedOptions !=null && selectedOptions.length > 0) ? 
      selectedOptions.map((item) => item.label).join(", "): null;

    localStorage.setItem("SelectedTo", SelectedTo);
    
    if(isComposeMode) {

      Office.context.mailbox.item.to.getAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          return;
        }       
        
       
            // debugger;
        // const To = asyncResult.value;
        let existingRecipients = asyncResult.value || [];
        if(selectedOptions !=null && selectedOptions.length > 0){
          selectedOptions.forEach(element => {
            if(existingRecipients.findIndex(item=>item.emailAddress ==element.value) <=0) {
             // debugger;
              const ContactsOptions = contacts.filter(
                (emailObj) =>
                  emailObj.contactEmails &&
                  emailObj.contactEmails.some((item) => item.email === element.value)
              )
              .map((contact) => ({
                type: contact.type, // Assuming 'type' is a property in the contact object
                fullName: `${contact.firstName} ${contact.lastName}`, // Combine first and last names
                companyName: contact.companyName // Assuming 'companyName' is a property in the contact object
              }));
             //  const ContactsOptions = contacts
              //.flatMap((item) => item.contactEmails)
              //.filter((emailObj) => emailObj.contactEmails && emailObj.contactEmails.findIndex(item=>item.email ==  element.value) > 0)
              //.filter((item) => item.type,  
             // debugger;
              let displayName = element.value; // (ContactsOptions[0].type == 2) ? ContactsOptions[0].companyName : ContactsOptions[0].fullName;
             if(ContactsOptions !=null && ContactsOptions.length > 0) {
              displayName = (ContactsOptions[0].type == 2) ? ContactsOptions[0].companyName : ContactsOptions[0].fullName;
             }

            const newRecipient = { emailAddress: element.value, displayName: displayName };
            const updatedRecipients =  [...existingRecipients, newRecipient];
            setToRecipients(updatedRecipients);
            }
          });
        }
      });
  }
    //localStorage.setItem("SelectedTo", (selectedOptions !=null) ? selectedOptions.label: null);
  };

  const handleMatterChange = (value: any) => {
	//debugger;
    setSelectedMatter(value);
	
	localStorage.setItem("SelectedMatter", (value !=null) ? value.label: null);
    setFormData((prev: any) => ({
      ...prev,
      matter: value,
    }));
  };

  const handleTagsChange = (value: any[]) => {
    setSelectedTags(value);
	localStorage.setItem("SelectedTags", 
		(value !=null && value.length > 0) ? 
		value.map((item) => item.label).join(", "):null);
    setFormData((prev: any) => ({
      ...prev,
      tag: value,
    }));
  };
  const [selectedFrom, setSelectedFrom] = useState({});
  const [selectedTo, setSelectedTo] = useState([]);
  const [selectedMatter, setSelectedMatter] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [hasAttachment, setHasAttachment] = useState(false);
  const [isComposeMode, setCompodeMode] = useState(false);
  const [AccessToken, SetAccessToken] = useState(null);
  const [errors, setErrors] = useState<any>({});
  const [isEmailLogged, setEmailLogged] = useState(false);
  const [isEmailRead, setIsEmailRead] = useState(false);
  const [IsLoader, setIsLoader] = useState(true);

  useEffect(() => {
    (async () => {
     // debugger;
      if (Reset == true) {
        SetIsReset(true);
        localStorage.setItem("state","1");
        if (isEmailRead == false) {
		      setIsEmailRead(true);
          Office.initialize = function () {
            Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, onItemChanged);
           };
          ReadEmailItem();
          // const selectedMatterMemory = localStorage.getItem("SelectedMatter");
          // if(selectedMatterMemory !=null) {
          //    const findMatter = sampleMatters.find(item=> item.label == selectedMatterMemory);
          //    if(findMatter !=null)
          //      setSelectedMatter(findMatter);	
          // }
        //setSelectedMatter(value);  
        }
      }
    })();
  }, [isEmailRead, Reset]);

//   useEffect(() => {
//     (async () => {
// 		var selectedMatterMemory = localStorage.getItem("SelectedMatter");
// 		if(selectedMatterMemory !=null) {
// 			 const findMatter = sampleMatters.find(item=> item.label == selectedMatterMemory);
// 			 if(findMatter !=null)
// 				 setSelectedMatter(findMatter);	
// 		}
//     })();
//   }, [dispatch,sampleMatters]);

const setToRecipients = (Emailobject: any) => {
  if (Office.context.mailbox.item) {
    // Use the API to set recipients in the "To" field
    const recipients = Emailobject;
    // [
    //   { emailAddress: Emailobject, displayName: Emailobject }
    //   // { emailAddress: "user2@domain.com", displayName: "User Two" },
    //   // { emailAddress: "user3@domain.com", displayName: "User Three" }
    // ];
    Office.context.mailbox.item.to.setAsync(
      recipients,
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          console.log('To recipients set successfully.');
        } else {
          console.error('Failed to set To recipients:', result.error);
        }
      }
    );
  } else {
    console.error('No mailbox item available.');
  }
};

const RemoveRecipients = (Emailobject: any) => {
  if (Office.context.mailbox.item) {
    // Use the API to set recipients in the "To" field

    const recipients = [
      { emailAddress: Emailobject, displayName: Emailobject }
      // { emailAddress: "user2@domain.com", displayName: "User Two" },
      // { emailAddress: "user3@domain.com", displayName: "User Three" }
    ];

    Office.context.mailbox.item.to.setAsync(
      recipients,
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          console.log('To recipients set successfully.');
        } else {
          console.error('Failed to set To recipients:', result.error);
        }
      }
    );
  } else {
    console.error('No mailbox item available.');
  }
};

const validateForm = (mode: any) => {
    if (!mode.cm) {
      return true;
    }
    let tempErrors: any = {};
    if (selectedMatter == null) {
      tempErrors.matter = "Please select a matter";
    }

    if (selectedTags.length === 0) {
      tempErrors.tags = "At least one tag is required";
    }
    if (!selectedFrom || !selectedFrom) {
      tempErrors.from = "Please select a sender";
    }
    if (selectedTo.length === 0) {
      tempErrors.to = "At least one recipient is required";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const Tag = ({ text, onRemove }) => (
    <span className="inline-flex items-center bg-blue-50 rounded-md px-2 py-1 m-1">
      <span className="text-sm text-gray-700">{text}</span>
      <button
        onClick={onRemove}
        className="ml-1 text-gray-400 hover:text-gray-600 focus:outline-none"
      >
        <X size={14} />
      </button>
    </span>
  );

  
  

  const Dropdown = ({
    label,
    value,
    options,
    onChange,
    isMulti,
    required,
  }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
    useEffect(() => {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);
    return (
      <div className="mb-4" ref={dropdownRef}>
        <label className="block">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
        <div className="relative">
          <div
            className="border rounded-lg p-2 min-h-[42px] cursor-text bg-white"
            onClick={() => setIsOpen(true)}
          >
            <ChevronDown
              className={`absolute right-2 top-3 text-gray-400 transition-transform ${
                isOpen ? "transform rotate-180" : ""
              }`}
              size={16}
            />
            {isMulti ? (
              <div className="flex flex-wrap gap-1">
                {value.map((v: any) => (
                  <span
                    key={v.value}
                    className="inline-flex items-center bg-blue-50 rounded-md px-2 py-1 m-1"
                  >
                    <span className="text-sm text-gray-700">{v.label}</span>
                    <button
                      onClick={() =>
                        onChange(
                          value.filter((item: any) => item.value !== v.value)
                        )
                      }
                      className="ml-1 text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      <X size={14} />
                    </button>
                  </span>
                ))}
                <input
                  type="text"
                  className="outline-none flex-grow min-w-[100px] text-sm p-1"
                  placeholder="Type to search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={() => setIsOpen(true)}
                />
              </div>
            ) : (
              <div className="flex items-center">
                {value ? (
                  <>
                    <span className="text-sm">{value.label}</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(null);
                      }}
                      className="ml-2 text-gray-400 hover:text-gray-600"
                    >
                      <X size={14} />
                    </button>
                  </>
                ) : (
                  <input
                    type="text"
                    className="outline-none w-full text-sm"
                    placeholder="Type to search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => setIsOpen(true)}
                  />
                )}
              </div>
            )}
          </div>

          {isOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    if (isMulti) {
                      onChange([...value, option]);
                    } else {
                      onChange(option);
                    }
                    setSearch("");
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="max-w-2xl  p-1 pl-4 bg-white">
      {!isEmailLogged && !IsLoader && (
        <h2 className="text-2xl font-bold mb-6">Complete Email Logging</h2>
      )}
      {isEmailLogged && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontSize: "18px",
              marginTop: "250px",
              fontWeight: "bold",
              color: "green",
            }}
          >
            This email is logged.
          </h2>
        </div>
      )}
      {!isEmailLogged && (
        <div>
          <FormControl fullWidth>
            <Dropdown
              label="From"
              value={selectedFrom}
              options={emailOptions}
              onChange={handleFromChange}
              isMulti={false}
              required
            />
            {!selectedFrom && errors.from && (
              <p className="text-red-500">{errors.from}</p>
            )}
          </FormControl>

          <FormControl fullWidth>
            <Dropdown
              label="To"
              value={selectedTo}
              options={emailOptions}
              onChange={handleToChange}
              isMulti={true}
              required
            />
            {!selectedTo && errors.to && (
              <p className="text-red-500">{errors.to}</p>
            )}
          </FormControl>

          <FormControl fullWidth>
            <Dropdown
              label="Matter"
              value={selectedMatter}
              options={sampleMatters}
              // onChange={setSelectedMatter}
              onChange={handleMatterChange}
              isMulti={false}
              required={false}
            />
            {!selectedMatter && errors.matter && (
              <p className="text-red-500">{errors.matter}</p>
            )}
          </FormControl>
          <FormControl fullWidth>
            <Dropdown
              label="Tags"
              value={selectedTags}
              options={sampleTags}
              // onChange={setSelectedTags}
              onChange={handleTagsChange}
              isMulti={true}
              required
            />
            {selectedTags.length == 0 && errors.tags && (
              <p className="text-red-500">{errors.tags}</p>
            )}
          </FormControl>
          <div className="mb-6">
            <h3 className="font-bold mb-4">Trigger Actions</h3>
            <div className="space-y-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.createCommunication}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      createCommunication: e.target.checked,
                    })
                  }
                  className="form-checkbox h-5 w-5 text-green-500 rounded"
                />
                <span className="ml-2">Log Communication</span>
              </label>
			  {!isComposeMode && (
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.createTimeslip}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      createTimeslip: e.target.checked,
                    })
                  }
                  className="form-checkbox h-5 w-5 text-green-500 rounded"
                />
                <span className="ml-2">Log Timeslip</span>
              </label>
	         )}
              {hasAttachment && (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.createClarraDocs}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        createClarraDocs: e.target.checked,
                      })
                    }
                    className="form-checkbox h-5 w-5 text-green-500 rounded"
                  />
                  <span className="ml-2">Log ClarraDocs</span>
                </label>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              className="px-6 py-2 border rounded-lg hover:bg-gray-50"
              onClick={onCancel}
            >
              Sign out
            </button>
            {!isComposeMode && <button
              type="submit"
              onClick={onSaveform}
              className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Log to Clarra
            </button>
           }
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailLoggingForm;
