import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import matterSlice from './matterSlice';
import tagSlice from './tagSlice';
import activityCodeSlice from './activityCodeSlice';
import taskCodeSlice from './taskCodeSlice';
import serviceTypeSlice from './serviceTypeSlice';
import statusSlice from './statusSlice';
import communicationSlice from './communicationSlice';
import clarraDocsSlice from './clarraDocsSlice';
import timeSlipSlice from './timeSlipSlice';
import contactSlice from './contactSlice';
import userSlice from './userSlice';
import communicationDetailSlice from './communicationDetailSlice';
// import authReducer from '../Redux/authSlice';
// import authSlice from '../Redux/authSlice';

const store = configureStore({
    reducer: {
        // auth: authReducer,
        auth: authSlice,
        matters:matterSlice,
        tags:tagSlice,
        activityCode:activityCodeSlice,
        taskCode:taskCodeSlice,
        serviceType:serviceTypeSlice,
        status:statusSlice,
        communication:communicationSlice,
        clarraDocs:clarraDocsSlice,
        timeSlip:timeSlipSlice,
        contact:contactSlice,
        user:userSlice,
        communicationDetail:communicationDetailSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
