import * as React from "react";
import * as ReactDOM from "react-dom";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import App from "./components/App";
import "./styles.less";
import "./global.css";
import "office-ui-fabric-react/dist/css/fabric.min.css";
import { Provider } from "react-redux";
import store from "./components/Redux/store";
import { Auth0Provider } from "@auth0/auth0-react";
import {API_BASE_URL, domain, audience, scope, clientId } from "./config/config";
import { SaveCommuncation } from "./components/Redux/communicationSlice";

initializeIcons();

let isOfficeInitialized = false;
window.dispatch = store.dispatch;
const title = "Office-Add-in-Microsoft-Graph-React";

const render = (Component) => {
	ReactDOM.render(
		<React.StrictMode>
			<Auth0Provider
				domain = {domain} //"clarra-qa.us.auth0.com"
				clientId= {clientId} //"4XEcTDvBRWpYUoLjDdREO3IycFqFlhvq"
				authorizationParams={{
					scope: "openid profile email",
					audience: audience, //"https://clarraqa/external/api",
					redirect_uri: window.location.origin,
				}}
				cacheLocation="localstorage"
			>
				<Provider store={store}>
					<Component
						title={title}
						isOfficeInitialized={isOfficeInitialized}
					/>
				</Provider>
			</Auth0Provider>
		</React.StrictMode>,
		document.getElementById("container")
	);
};

/* Render application after Office initializes */
Office.initialize = () => {
	localStorage.removeItem("attachments");
	localStorage.removeItem("state");
	console.log("Office init from index.tsx")
	Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, onItemChanged);
         
	isOfficeInitialized = true;
	render(App);
};
function onItemChanged(eventArgs) {
    // Called when the user selects a different email item
	const item = Office.context.mailbox.item;
    if (item) {
      console.log("Item changed to:", item.subject);
	  render(App);
  }
}
/* Initial render showing a progress bar */
render(App);

if ((module as any).hot) {
	(module as any).hot.accept("./components/App", () => {
		const NextApp = require("./components/App").default;
		render(NextApp);
	});
}