import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, GetServiceTypes} from "../../config/config";
var AccessToken = localStorage.getItem('AccessToken1');
export const fetchAllServiceType = createAsyncThunk(
  'serviceType/fetchAllServiceType',
  async (AccessTokenParam:any, { rejectWithValue }) => {
    try {
      if(AccessToken ==null){
        AccessToken = AccessTokenParam;
      }
      const response = await axios.get(API_BASE_URL + GetServiceTypes , {
        headers: {
          Authorization : `Bearer ` + AccessToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'mode': 'outlook'
        },
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const serviceTypeSlice = createSlice({
  name: 'serviceType',
  initialState: {
    serviceType: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllServiceType.pending, (state) => {
        state.loading = true;
       // state.error = null;
      })
      .addCase(fetchAllServiceType.fulfilled, (state, action) => {
       // debugger;
        state.loading = false;
        state.error = "200";
        state.serviceType = action.payload;
      })
      .addCase(fetchAllServiceType.rejected, (state, action) => {
       // debugger;
        state.loading = false;
        state.error ="401";
      });
  },
});

export default serviceTypeSlice.reducer;
