import React, { useState, useRef, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { useAlert } from "../MessageAlert/AlertContext";
import { AppDispatch, RootState } from "../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchAllUsers } from "../Redux/userSlice";
import { FormControl } from "@mui/material";

const AutocompleteInput = ({
  label,
  value,
  options,
  onChange,
  required,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const wrapperRef = useRef(null);
  useEffect(() => {
    // Set initial selected option based on value
    const option = options.find((opt) => opt.value === value);
    if (option) {
      setSelectedOption(option);
      setSearchTerm(option.label);
    }
  }, [value, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        if (selectedOption) {
          setSearchTerm(selectedOption.label);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [selectedOption]);

  const filteredOptions = options.filter((option) => option);
  const handleSelect = (option) => {
    setSelectedOption(option);
    setSearchTerm(option.label);
    setIsOpen(false);
    onChange(option.value);
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <label className="block text-sm mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pr-8"
        />
        <ChevronDown
          className={`absolute right-2 top-3 text-gray-400 transition-transform ${
            isOpen ? "transform rotate-180" : ""
          }`}
          size={16}
        />
      </div>
      {isOpen && filteredOptions.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto">
          {filteredOptions.map((option) => (
            <div
              key={option.id}
              onClick={() => handleSelect(option)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const LogTimeslipForm = ({
  setOnlyTimeSlip,
  setCommunicationSave,
  data,
  handleSubmitNewTimeSlip,
}) => {
  const SavedData = data;
  const dispatch = useDispatch<AppDispatch>();
  const matters = useSelector((state: RootState) => state.matters.matters);
  let mattersoptions = matters
    .filter((mattersObj) => mattersObj && mattersObj.matter)
    .map((mattersObj) => ({
      value: mattersObj.matter + " (" + mattersObj.number + ")",
      label: mattersObj.matter + " (" + mattersObj.number + ")",
    }));
  const activityCode = useSelector(
    (state: RootState) => state.activityCode.activityCode
  );
  let activityCodeOptions = activityCode
    .filter((activityCodeObj) => activityCodeObj && activityCodeObj.name)
    .map((activityCodeObj) => ({
      value: activityCodeObj.name,
      label: activityCodeObj.name,
    }));
  const taskCode = useSelector((state: RootState) => state.taskCode.taskCode);
  let taskCodeOptions = taskCode
    .filter((taskCodeObj) => taskCodeObj && taskCodeObj.name)
    .map((taskCodeObj) => ({
      value: taskCodeObj.name,
      label: taskCodeObj.name,
    }));
  const users = useSelector((state: RootState) => state.user.users);
  let emailOptions = users
    ? users.map((emailObj) => ({
        value: emailObj.initials,
        label: emailObj.userName,
      }))
    : [];

  console.log("emailOptions", emailOptions);
  const [AccessToken, SetAccessToken] = useState(null);
  // console.log('emailOptions',emailOptions);
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    (async () => {
      const x = getAccessToken();
      if (AccessToken != null) {
        //debugger;
        localStorage.setItem("AccessToken1", AccessToken);
        dispatch(fetchAllUsers(AccessToken));
        const m = localStorage.getItem("SelectedMatter");
        if (m != null) formData.matter = m;
      }
    })();
  }, [dispatch]);

  const getAccessToken = async () => {
    //debugger;
    let token;
    try {
      token = localStorage.getItem("AccessToken1");
      if (token == null) {
        token = await getAccessTokenSilently();
      }
      if (token != null) {
        localStorage.removeItem("AccessToken1");
        localStorage.setItem("AccessToken1", token);
        SetAccessToken(token);
      }
    } catch (exception) {}
    return token;
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  // ToLocaleDateString
  //const formattedDate = now.toUTCString();
  const [formData, setFormData] = useState({
    billable: true,
    date: formatDate(new Date()),
    duration: "00.00",
    timekeeper: "",
    matter: SavedData?.matter?.label !== "" ? SavedData?.matter?.label : "",
    taskCode: "",
    activityCode: "",
    description: "",
    note: "",
    data: "",
  });
  const [errors, setErrors] = useState({
    billable: true,
    date: "",
    duration: "",
    timekeeper: "",
    matter: "",
    taskCode: "",
    activityCode: "",
    description: "",
    note: "",
  });

  const validateForm = () => {
    let tempErrors: any = {};

    if (!formData.date) {
      tempErrors.date = "Date is required";
    }

    if (!formData.duration || formData.duration == "00.00") {
      tempErrors.duration = "Duration is required";
    }

    if (!formData.timekeeper) {
      tempErrors.timekeeper = "Timekeeper is required";
    }

    if (!formData.matter) {
      tempErrors.matter = "Matter is required";
    }

    if (!formData.taskCode) {
      tempErrors.taskCode = "Task Code is required";
    }

    if (!formData.activityCode) {
      tempErrors.activityCode = "Activity Code is required";
    }

    if (!formData.description) {
      tempErrors.description = "Description is required";
    }

    // if (!formData.note) {
    //   tempErrors.note = "Note is required";
    // }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const onCancel = () => {
    setCommunicationSave(false);
    setOnlyTimeSlip(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      formData.data = AccessToken;
      handleSubmitNewTimeSlip(formData);
    }
  };
  return (
    <div className="bg-white max-w-2xl w-full pl-4 p-1">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold">Log Timeslip to Clarra</h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Billable/Non-Billable Radio */}
        <div className="flex space-x-6">
          <label className="flex items-center">
            <input
              type="radio"
              name="billable"
              checked={formData.billable}
              onChange={() =>
                setFormData((prev) => ({
                  ...prev,
                  billable: true,
                }))
              }
              className="w-4 h-4 text-blue-600"
            />
            <span className="ml-2">Billable</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="billable"
              checked={!formData.billable}
              onChange={() =>
                setFormData((prev) => ({
                  ...prev,
                  billable: false,
                }))
              }
              className="w-4 h-4 text-blue-600"
            />
            <span className="ml-2">Non-Billable</span>
          </label>
        </div>

        {/* Date and Duration Row */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm mb-1">
              Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              value={formData.date}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  date: e.target.value,
                }))
              }
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {!formData.date && errors.date && (
              <p className="text-red-500 text-xs">{errors.date}</p>
            )}
          </div>
          <div>
            <label className="block text-sm mb-1">
              Duration (hr) <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.duration}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  duration: e.target.value,
                }))
              }
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {!formData.duration ||
              (formData.duration == "00:00" && errors.duration && (
                <p className="text-red-500 text-xs">{errors.duration}</p>
              ))}
          </div>
        </div>

        {/* Timekeeper and Matter Row */}
        {/* <div className="grid grid-cols-2 gap-4"> */}
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <AutocompleteInput
            label="Timekeeper"
            value={formData.timekeeper}
            options={emailOptions}
            onChange={(value) =>
              setFormData((prev) => ({
                ...prev,
                timekeeper: value,
              }))
            }
            required
            placeholder="Select Timekeeper"
          />
          {!formData.timekeeper && errors.timekeeper && (
            <p className="text-red-500 text-xs">{errors.timekeeper}</p>
          )}
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <AutocompleteInput
            label="Matter"
            value={formData.matter}
            options={mattersoptions}
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, matter: value }))
            }
            required
            placeholder="Select matter"
          />
          {!formData.matter && errors.matter && (
            <p className="text-red-500 text-xs">{errors.matter}</p>
          )}
        </FormControl>
        {/* </div> */}

        {/* Task Code and Activity Code Row */}
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <AutocompleteInput
            label="Task Code"
            value={formData.taskCode}
            options={taskCodeOptions}
            onChange={(value) =>
              setFormData((prev) => ({
                ...prev,
                taskCode: value,
              }))
            }
            required
            placeholder="Select task code"
          />
          {!formData.taskCode && errors.taskCode && (
            <p className="text-red-500 text-xs">{errors.taskCode}</p>
          )}
        </FormControl>
        <FormControl fullWidth>
          <AutocompleteInput
            label="Activity Code"
            value={formData.activityCode}
            options={activityCodeOptions}
            onChange={(value) =>
              setFormData((prev) => ({
                ...prev,
                activityCode: value,
              }))
            }
            required
            placeholder="Select activity code"
          />
          {!formData.activityCode && errors.activityCode && (
            <p className="text-red-500 text-xs">{errors.activityCode}</p>
          )}
        </FormControl>

        {/* Description Field */}
        <div>
          <label className="block text-sm mb-1">
            Description <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={formData.description}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Note Field */}
        <div>
          <label className="block text-sm mb-1">Note</label>
          <textarea
            value={formData.note}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                note: e.target.value,
              }))
            }
            placeholder="Enter your note here"
            rows={4}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-4">
          <button
            onClick={onCancel}
            type="button"
            className="px-4 py-2 border rounded hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Log Timeslip
          </button>
        </div>
      </form>
    </div>
  );
};

export default LogTimeslipForm;
