import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, CreateClarraDocsform} from "../../config/config";

var AccessToken = localStorage.getItem('AccessToken1');
export const SaveClarraDocs = createAsyncThunk(
  'clarraDocs/SaveClarraDocsLog',
   async (FormData:any) => {
    // debugger;
   // console.log(FormData);
    if(AccessToken ==null){
      AccessToken = FormData.data;
    }
    FormData.data ="";
    let index =1;
    FormData.UploadFileAttachments.forEach(element => {
      //debugger;
      if(element.filename !=""){
        ;
        const file =  new File([element.blobfile], element.filename);
       // debugger;
        switch(index){
          case 1 :  FormData.UploadSingleAttachments1 = file;break;
          case 2 :  FormData.UploadSingleAttachments2 = file; break;
          case 3 :  FormData.UploadSingleAttachments3 = file; break;
          case 4 :  FormData.UploadSingleAttachments4 = file; break;
      }
      index = index +1;
    } 
    FormData.UploadFileAttachments = null;
    });
    //debugger
    console.log(API_BASE_URL + CreateClarraDocsform);
   const response  = await axios.post(API_BASE_URL + CreateClarraDocsform , FormData, {
    headers: {
      Authorization : `Bearer ` + AccessToken,
      //'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      'mode': 'outlook'
    },
  });

  return response;

  }
);

const clarraDocsSlice = createSlice({
  name: 'clarraDocs',
  initialState: {
    clarraDocs : null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SaveClarraDocs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveClarraDocs.fulfilled, (state, action) => {
        state.loading = false;
        state.clarraDocs = action.payload;
      })
      .addCase(SaveClarraDocs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default clarraDocsSlice.reducer;
