import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Box } from '@mui/material';

const CommunicationStatus = ({mode, show}) => {
  
  if(!show) {
    return (<div></div>);
  }

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <CheckCircleIcon color="success" fontSize="large" />
      <Typography variant="body1" color="success.main" ml={1}>
        {mode} successfully logged!
      </Typography>
    </Box>
  );

};

export default CommunicationStatus;
// import React, { useState, useEffect } from 'react';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { Typography, Box } from '@mui/material';

// const CommunicationStatus = ({ delay = 2000 }) => {
//   const [isVisible, setIsVisible] = useState(true);

//   useEffect(() => {
//     // setIsVisible(true);
//     const timer = setTimeout(() => {
//         setIsVisible(false);
//     }, delay);

//     return () => clearTimeout(timer);
//   }, [delay,isVisible]);
//   if (!isVisible) return null;


// function setIsVisible(arg0: boolean) {
//   throw new Error('Function not implemented.');
// }
//   return (
//     <Box display="flex" alignItems="center" mt={2}>
//       <CheckCircleIcon color="success" fontSize="large" />
//       <Typography variant="body1" color="success.main" ml={1}>
//         Email successfully logged!
//       </Typography>
//     </Box>
//   );
// };

// export default CommunicationStatus;
