import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, CreateCommunicationsform} from "../../config/config";

var AccessToken = localStorage.getItem('AccessToken1');
export const SaveCommuncation = createAsyncThunk(
  'communication/SaveCommuncation',
   async (Cdata:any) => {
     //debugger;
        if(AccessToken ==null){
          AccessToken = Cdata.filter;
        }
        Cdata.filter ="";
      let index =1;
      Cdata.UploadFileAttachments.forEach(element => {
        if(element.filename !=""){
         // const file =  new File([Cdata.UploadFileAttachments.blobfile], Cdata.UploadFileAttachments.filename);
          let file =  new File([element.blobfile], element.filename);
          switch(index){
            case 1 :  Cdata.UploadSingleAttachments1 = file;break;
            case 2 :  Cdata.UploadSingleAttachments2 = file; break;
            case 3 :  Cdata.UploadSingleAttachments3 = file; break;
            case 4 :  Cdata.UploadSingleAttachments4 = file; break;
        }
        index = index +1;
      } 
      });
     // debugger;
       const response = await axios.post(API_BASE_URL + CreateCommunicationsform , Cdata, {
        headers: {
          Authorization : `Bearer ` + AccessToken,
          //'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
          'mode': 'outlook'
        },
      }); 
       return response.data;
  }
);

const communicationSlice = createSlice({
  name: 'communication',
  initialState: {
    communication : null,
    communicationDetail :null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SaveCommuncation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveCommuncation.fulfilled, (state, action) => {
        state.loading = false;
        state.communication = action.payload;
      })
      .addCase(SaveCommuncation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default communicationSlice.reducer;
