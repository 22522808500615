import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_BASE_URL, CreateTimeslip } from "../../config/config";

var AccessToken = localStorage.getItem('AccessToken1');

export const SaveTimeSlip = createAsyncThunk(
  'timeslip/SaveTimeSlip',
   async (FormData:any) => {
    //debugger;
    //console.log(FormData);
     if(AccessToken ==null){
        AccessToken = FormData.data;
      } 
      FormData.data ="";
       const response  = await axios.post(API_BASE_URL + CreateTimeslip , FormData, {
        headers: {
          Authorization : `Bearer ` + AccessToken,
          'Content-Type': 'application/json',
          Accept: '*/*',
          'mode': 'outlook'
        },
      });

      return response;

      // }).then(response => {
      //   console.log('Success:', response.data);
      // }).catch(error => {
      //   console.error('Error:', error);
      // });
  }
);

const timeSlipSlice = createSlice({
  name: 'timeSlip',
  initialState: {
    timeslip :null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SaveTimeSlip.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveTimeSlip.fulfilled, (state, action) => {
       // debugger;
        state.loading = false;
        state.timeslip = action.payload;
      })
      .addCase(SaveTimeSlip.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default timeSlipSlice.reducer;
