import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_BASE_URL,
  GetCommunicationByExternalId,
} from "../../config/config";

var AccessToken = localStorage.getItem("AccessToken1");

export const GetcommunicationDetail = createAsyncThunk(
  "communication/GetcommunicationDetail",
  async (ParamObject: any, { rejectWithValue }) => {
    if (AccessToken == null) {
      AccessToken = ParamObject.AccessTokenPAram;
    }
    try {
      const externalID = ParamObject.externalID;
      // console.log(
      //   "GetcommunicationDetail " + API_BASE_URL + GetCommunicationByExternalId
      // );
      const response = await axios.get(
        API_BASE_URL + GetCommunicationByExternalId,
        {
          headers: {
            Authorization: `Bearer ` + AccessToken,
            "Content-Type": "application/json",
            Accept: "application/json",
            ExternalId: externalID,
            'mode': "outlook",
          },
        }
      );
      return response ? response.data : null;
    } catch (error) {
      return rejectWithValue(error.response); // Return error message
    }
  }
);

const communicationDetailSlice = createSlice({
  name: "communicationDetail",
  initialState: {
    communicationDetail: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetcommunicationDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetcommunicationDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.communicationDetail = action.payload;
      })
      .addCase(GetcommunicationDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default communicationDetailSlice.reducer;
