import React, { useEffect, useRef, useState } from "react";
import { ChevronDown, Search } from "lucide-react";
import { X } from "lucide-react";
import { useAlert } from "../MessageAlert/AlertContext";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import { FormControl } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const Dropdown = ({
	label,
	value,
	options,
	onChange,
	isMulti,
	required,
  }: any) => {
	const [isOpen, setIsOpen] = useState(false);
	const [search, setSearch] = useState("");
	const dropdownRef = useRef<HTMLDivElement>(null); 

	const filteredOptions = options?.filter((option) =>
	  option.label?.toLowerCase()?.includes(search?.toLowerCase())
	);
  
	const handleRemove = (optionToRemove: any) => {
	  const newValue = value.filter(
		(item: any) => item.value !== optionToRemove.value
	  );
	  onChange(newValue);
	};
	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
		  if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		  ) {
			setIsOpen(false);
		  }
		};
	
		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
		  document.removeEventListener("mousedown", handleOutsideClick);
		};
	  }, []);
	return (
	  <div className="" ref={dropdownRef}>
		<label className="block">
		  {label}
		  {required && <span className="text-red-500">*</span>}
		</label>
		<div className="relative">
		  <div
			className="border rounded-lg p-2 min-h-[42px] cursor-text bg-white"
			onClick={() => setIsOpen(true)}
		  >
			{isMulti ? (
			  <div className="flex flex-wrap gap-1">
				{value?.map((v: any) => (
				  <span
					key={v?.value}
					className="inline-flex items-center bg-blue-50 rounded-md px-2 py-1 m-1"
				  >
					<span className="text-sm text-gray-700">{v?.label}</span>
					<button
					  onClick={(e) => {
						e.stopPropagation();
						handleRemove(v);
					  }}
					  className="ml-1 text-gray-400 hover:text-gray-600 focus:outline-none"
					>
					  <X size={14} />
					</button>
				  </span>
				))}
				<input
				  type="text"
				  className="outline-none flex-grow min-w-[100px] text-sm p-1"
				  placeholder="Type to search..."
				  value={search}
				  onChange={(e) => setSearch(e.target.value)}
				  onFocus={() => setIsOpen(true)}
				/>
			  </div>
			) : (
			  <div className="flex items-center">
				{value ? (
				  <>
					<span className="text-sm">{value.label}</span>
					<button
					  onClick={(e) => {
						e.stopPropagation();
						onChange(null);
					  }}
					  className="ml-2 text-gray-400 hover:text-gray-600"
					>
					  <X size={14} />
					</button>
				  </>
				) : (
				  <input
					type="text"
					className="outline-none w-full text-sm"
					placeholder="Type to search..."
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					onFocus={() => setIsOpen(true)}
				  />
				)}
			  </div>
			)}
		  </div>
  
		  {isOpen && (
			<div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
			  {filteredOptions.map((option) => (
				<div
				  key={option.value}
				  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
				  onClick={() => {
					if (isMulti) {
					  // Avoid duplicates in the array
					  if (!value.find((item: any) => item.value === option.value)) {
						onChange([...value, option]);
					  }
					} else {
					  onChange(option);
					}
					setSearch("");
					setIsOpen(false);
				  }}
				>
				  {option.label}
				</div>
			  ))}
			</div>
		  )}
		  	<ChevronDown
					className={`absolute right-2 top-3 text-gray-400 transition-transform ${
						isOpen ? "transform rotate-180" : ""
					}`}
					size={16}
				/>
		</div>
	  </div>
	);
  };
  
const LogClarraDocsForm = ({setCommunicationSave,setOnlyDocs,ResetFormData,data,handleSubmitNewClarraDocs, files}) => {
	const { showAlert } = useAlert();
	const SavedData = data;
	const dispatch = useDispatch<AppDispatch>();
	const matters = useSelector((state: RootState) => state.matters.matters);
	  const mattersOptions = matters.map((matter) => ({
		value: matter.id,
		label: matter.matter + " (" + matter.number + ")",
	}));
	const fileArray = files.map((item) => ({
		filename: item.filename
	  }));
	const ServiceType = useSelector(
		(state: RootState) => state.serviceType.serviceType
	);
	const serviceTypeOptions = ServiceType.map((service) => ({
		label: service.name, 
		value: service.id,    
	  }));
	const tags = useSelector((state: RootState) => state.tags.tags);
	const [AccessToken, SetAccessToken] = useState(null);

	const {getAccessTokenSilently} = useAuth0();
	const tagsOptions = tags.map((tags) => ({
		label: tags.name, 
		value: tags.id,     
	  }));

	  const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};
	const [formData, setFormData] = useState({
		matter:  
			SavedData?.matter?.label
			  ? { label: SavedData.matter.label, value: SavedData.matter.value }
			  : "",
		date: formatDate(new Date()),
		status: "",
		serviceType: "",
		tags: 
			Array.isArray(SavedData?.tag) && SavedData?.tag.length > 0
			  ? SavedData.tag.map((item) => ({ label: item.label, value: item.value }))
			 : [],
		description: "",
		note: "",
		attachments: files,
		sendToDocketing: false,
		docketingComplete: false,
		docketingNotes: "",
		data:"",
	});
	const [errors, setErrors] = useState({
		matter: "",
		date: "",
		status: "",
		serviceType: "",
		tags: [],
		description: "",
	  });
	  const validateForm = () => {
		const newErrors: any = {};
		if (!formData.matter) newErrors.matter = "Matter is required.";
		if (!formData.date) newErrors.date = "Date is required.";
		if (!formData.status) newErrors.status = "Status is required.";
		if (!formData.serviceType) newErrors.serviceType = "Service Type is required.";
		if (formData.tags.length === 0)newErrors.tags = "At least one tag is required.";
		if (!formData.description) newErrors.description = "Description is required.";
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	  };
	const onCancel = ()=>{
		setCommunicationSave(false);
		setOnlyDocs(false);
	}
	
	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
		setCommunicationSave(false);
		formData.data = AccessToken;
		formData.attachments = files;
		handleSubmitNewClarraDocs(formData);
		setOnlyDocs(false);
		ResetFormData();
		}
	};
	const removeFile = (index) => {
		const newFiles = [...formData.attachments];
		newFiles.splice(index, 1);
		setFormData({ ...formData, attachments: newFiles });
	};
	
	useEffect(() => {
		(async () => {
			const x = getAccessToken();
		})();
	}, [dispatch, AccessToken]);
	const getAccessToken = async () => {
		let token;
		try {
			token = localStorage.getItem('AccessToken1');
			if(token ==  null) {
				token = await getAccessTokenSilently();
			}
			if(token !=null) {
				localStorage.removeItem('AccessToken1');
				localStorage.setItem('AccessToken1', token);
				SetAccessToken(token);
			}
		} catch (exception) {
		
		}
		return token;
	};

	return (
		<div className="bg-white max-w-2xl w-full pl-4  p-1">
			<div className="flex justify-between items-center mb-6">
				<h2 className="text-xl font-bold">Log ClarraDocs to Clarra</h2>
			</div>

			<form onSubmit={handleSubmit} className="space-y-4">
				{/* Matter and Date Row */}
				<FormControl  fullWidth sx={{marginTop:'10px'}}>
						<Dropdown
                 label="Matter"
					value={formData.matter}
                   options={mattersOptions}
				   onChange={(value) =>
					setFormData({ ...formData, matter: value })
				}
                    isMulti={false}
                    required
                />	
				          {!formData.matter && errors.matter && <span className="text-red-500 text-sm">{errors.matter}</span>}
						  </FormControl>
					<div>
						<label className="block text-sm text-gray-600 mb-1">
							Date <span className="text-red-500">*</span>
						</label>
						<input
							type="date"
							value={formData.date}
							onChange={(e) =>
								setFormData({
									...formData,
									date: e.target.value,
								})
							}
							className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
						/>
						            {!formData.date && errors.date && <span className="text-red-500 text-sm">{errors.date}</span>}
					</div>
				{/* Status and Service Type Row */}
				<FormControl  fullWidth sx={{marginTop:'10px'}}>
					<Dropdown
             label="Status"
			 value={formData.status}
			 options={[	{label:"Received"}, {label:"Pending"}, {label:"Completed"}]}
				   onChange={(value) =>
					setFormData({ ...formData, status: value })
				}
                    isMulti={false}
                    required
                />	
				          {!formData.status && errors.status && <span className="text-red-500 text-sm">{errors.status}</span>}
						  </FormControl>
						  <FormControl  fullWidth >
					<Dropdown
           	label="Service Type"
			   options={serviceTypeOptions}
				   value={formData.serviceType}
				   onChange={(value) =>
					   setFormData({ ...formData, serviceType: value })
				   }
                    isMulti={false}
                    required
                />	
				          {!formData.serviceType && errors.serviceType && <span className="text-red-500 text-sm">{errors.serviceType}</span>}
				</FormControl>
				{/* Tags */}
				<div className="relative">
					<div className="relative">
						<FormControl  fullWidth sx={{marginTop:'10px'}}>
					<Dropdown
          label="Tags"
		  value={formData.tags}
		  options={tagsOptions}			   
		onChange={(value) =>
			setFormData({ ...formData, tags: value })
		}
		
			className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pr-10"
                    isMulti={true}
                    required
                />
				          {(formData.tags.length === 0) && errors.tags && <span className="text-red-500 text-sm">{errors.tags}</span>}
				</FormControl>
					</div>
				</div>

				{/* Description */}
				<div>
					<label className="block text-sm text-gray-600 mb-1">
						Description <span className="text-red-500">*</span>
					</label>
					<input
						type="text"
						value={formData.description}
						onChange={(e) =>
							setFormData({
								...formData,
								description: e.target.value,
							})
						}
						className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Note */}
				<div>
					<label className="block text-sm text-gray-600 mb-1">
						Note
					</label>
					<textarea
						value={formData.note}
						onChange={(e) =>
							setFormData({ ...formData, note: e.target.value })
						}
						placeholder="Enter your note here"
						rows={4}
						className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Attachments */}
				<div>
					<label className="block text-sm text-gray-600 mb-1">
						Attachment <span className="text-red-500">*</span>
					</label>
					{formData.attachments.length == 0 && <div className="flex items-center justify-between p-2 border-b text-red-600">
						Atleast one attachment required.
					</div>
					}
					{formData.attachments.map((file, index) => (
						<div
							key={index}
							className="flex items-center justify-between p-2 border-b"
						>
							<span className="text-sm text-gray-600">
								{file.filename}
							</span>
							{formData.attachments.length > 1 &&	<button
									type="button"
									onClick={() => removeFile(index)}
									className="text-gray-400 hover:text-gray-600"
								>
									<X size={16} />
								</button> 
							}
							{/* } */}
						</div>

					))}
				</div>
				{/* Dotted line separator */}
				<div className="border-t border-dotted my-6"></div>

				{/* Docketing Section */}
				<div className="space-y-4">
					<label className="flex items-center space-x-2">
						<input
							type="checkbox"
							checked={formData.sendToDocketing}
							onChange={(e) =>
								setFormData({
									...formData,
									sendToDocketing: e.target.checked,
								})
							}
							className="form-checkbox h-4 w-4 text-blue-600"
						/>
						<span className="text-sm text-gray-600">
							Send to Docketing
						</span>
					</label>

					<label className="flex items-center space-x-2">
						<input
							type="checkbox"
							checked={formData.docketingComplete}
							onChange={(e) =>
								setFormData({
									...formData,
									docketingComplete: e.target.checked,
								})
							}
							className="form-checkbox h-4 w-4 text-blue-600"
						/>
						<span className="text-sm text-gray-600">
							Docketing Complete
						</span>
					</label>

					<div>
						<label className="block text-sm text-gray-600 mb-1">
							Docketing Notes
						</label>
						<textarea
							value={formData.docketingNotes}
							onChange={(e) =>
								setFormData({
									...formData,
									docketingNotes: e.target.value,
								})
							}
							rows={3}
							className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
						/>
					</div>
				</div>

				{/* Form Actions */}
				<div className="flex justify-end space-x-4 pt-4">
					<button
						onClick={onCancel}
						type="button"
						className="px-4 py-2 border rounded hover:bg-gray-50"
					>
						Cancel
					</button>
				 {formData.attachments.length > 0 && 
				  <button
						type="submit"
						className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
					>
						Log ClarraDocs
					</button> 
					  } 
				</div>
			</form>
		</div>
	);
};

export default LogClarraDocsForm;
