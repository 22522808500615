import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./Redux/store";
import { fetchAllMatters } from "./Redux/matterSlice";
import { fetchAllTags } from "./Redux/tagSlice";
import { fetchAllServiceType } from "./Redux/serviceTypeSlice";
import { fetchAllActivityCode } from "./Redux/activityCodeSlice";
import { fetchAllTaskCode } from "./Redux/taskCodeSlice";
import EmailLoggingForm from "./ui/EmailLog";
import LogClarraDocsForm from "./ui/LogClarraDocsForm";
import LogTimeslipForm from "./ui/LogTimeslipForm";
import { SaveCommuncation } from "./Redux/communicationSlice";
import { useAlert } from "./MessageAlert/AlertContext";
import { SaveTimeSlip } from "./Redux/timeSlipSlice";
import { SaveClarraDocs } from "./Redux/clarraDocsSlice";
import { Box } from "@mui/material";
import Loader from "./MessageAlert/Loader";
import CommunicationStatus from "./MessageAlert/SuccessAlert";
import Status from "./ui/Status";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchAllContacts } from "./Redux/contactSlice";
import { fetchAllUsers } from "./Redux/userSlice";
import Permission from "./ui/Permission";
export interface SuccessPageBodyProps {}

const BaseComponent: React.FC<SuccessPageBodyProps> = () => {
  const [isOnlyTimeSlip, setOnlyTimeSlip] = useState(false);
  const [isCommunicationEnabled, setCommunicationEnabled] = useState(false);
  const [isOnlyDocs, setOnlyDocs] = useState(false);
  const [isCommunicationSave, setCommunicationSave] = useState(true);
  const [mode, setMode] = useState("Email");
  const [showSuccess, setShowSuccess] = useState(false);
  const [checkCMode, setCheckCMode] = useState(false);
  const [checkTMode, setCheckTMode] = useState(false);
  const [checkDMode, setCheckDMode] = useState(false);
  const [EmailItem, SetEmailItem] = useState({});
  const [show, SetShowSuccessMessage] = useState(false);
  const [formData, setFormData] = useState({
    matter: "",
    tag: [],
    activityCode: "",
    taskCode: "",
    status: "",
    serviceType: "",
    createClarraDocs: false,
    createCommunication: true,
    createTimeslip: false,
    mailItem: {},
    mailbody: "",
  });

  const [errors, setErrors] = useState({
    matter: "",
    tag: "",
    activityCode: "",
    taskCode: "",
    status: "",
    serviceType: "",
    contact: "",
  });
  const dispatch = useDispatch<AppDispatch>();
  const LoginError = useSelector((state: RootState) => state.serviceType.error);
  const communication = useSelector(
    (state: RootState) => state.communication.communication
  );
  const ErrorOnSaveCommuncation = useSelector(
    (state: RootState) => state.communication.error
  );
  const loading = useSelector(
    (state: RootState) => state.communication.loading
  );
  const timeSlip = useSelector((state: RootState) => state.timeSlip.timeslip);
  const timeSlipLoading = useSelector(
    (state: RootState) => state.timeSlip.loading
  );
  const clarraDocs = useSelector(
    (state: RootState) => state.clarraDocs.clarraDocs
  );
  const clarraDocsLoading = useSelector(
    (state: RootState) => state.clarraDocs.loading
  );

  //console.log("clarraDocsLoading",loading,timeSlipLoading,clarraDocsLoading);
  const { getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();
  const [AccessToken, SetAccessToken] = useState(null);
  const [addCommunication, SetAddCommunication] = useState(false);
  const [addTimeSlip, SetAddTimeslip] = useState(false);
  const [addClarraDocs, SetAddClarraDocs] = useState(false);
  const [isValidToken, SetisValidToken] = useState(false);

  const [files, SetFiles] = useState([
    {
      blobfile: null,
      filename: "",
    },
  ]);
  const [Logmode, SetLogMode] = useState({
    cm: false,
    tm: false,
    dm: false,
  });
  const ServiceType = useSelector(
    (state: RootState) => state.serviceType.serviceType
  );

  const SetCommunicationMode = () => {
    setCommunicationSave(false);
    setShowSuccess(false);
  };

  const onCancel = () => {
    // logout();
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("AccessToken1");
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };
  const getClaims = async () => {
    //debugger;
    console.log("Always Claim");
    var claims = await getIdTokenClaims();
    var x = claims["https://clarra/permissions"];
    const numbers = x;
    const checkNumbers = [15, 16, 105];

    const results = checkNumbers.map((num) => ({
      number: num,
      exists: numbers.includes(num),
    }));
    results.forEach((element) => {
      if (element.number == 15 && element.exists) {
        SetAddCommunication(true);
      }
      if (element.number == 16 && element.exists) {
        SetAddTimeslip(true);
      }
      if (element.number == 105 && element.exists) {
        SetAddClarraDocs(true);
        setCommunicationEnabled(true);
      }
    });
  };
  
  const getAccessToken = async () => {
    let token = AccessToken;
    if (AccessToken == null) {
      try {
        getClaims();
        token = localStorage.getItem("AccessToken1");
        if (token == null) {
          token = await getAccessTokenSilently();
          if (token == null) token = await getAccessTokenSilently();
        }

        if (token != null) {
          SetAccessToken(token);
          SetCommunicationMode();
          localStorage.removeItem("AccessToken1");
          localStorage.setItem("AccessToken1", token);
        }
      } catch (exception) {}
    }
    return token;
  };
  const ResetFormData = () => {
    setFormData({
      matter: "",
      tag: [],
      activityCode: "",
      taskCode: "",
      status: "",
      serviceType: "",
      createClarraDocs: false,
      createCommunication: true,
      createTimeslip: false,
      mailItem: {},
      mailbody: "",
    });
  };

  useEffect(() => {
    if (communication) {
      SetShowSuccessMessage(true);
      if (checkCMode) {
        setMode("Email");
        if (checkTMode) {
          setCommunicationSave(true);
          setOnlyTimeSlip(true);
        } else if (checkDMode) {
          setCommunicationSave(true);
          setOnlyDocs(true);
        } else {
          setCommunicationSave(true);
          setShowSuccess(true);
        }
      }
    }
  }, [communication]);

  useEffect(()=>{

    if(ErrorOnSaveCommuncation)
    {
      console.log("Error Saving ", ErrorOnSaveCommuncation);
      SetShowSuccessMessage(false);
    }
  }, [ErrorOnSaveCommuncation]);

  useEffect(() => {
    if (timeSlip) {
      setMode("TimeSlip");
      SetShowSuccessMessage(true);
      if (checkTMode) {
        setOnlyTimeSlip(false);
        if (checkDMode) {
          setCommunicationSave(true);
          setOnlyDocs(true);
        } else {
          setCommunicationSave(true);
          setShowSuccess(true);
        }
      }
    }
  }, [timeSlip]);

  useEffect(() => {
    if (clarraDocs) {
      setMode("ClarraDocs");
      SetShowSuccessMessage(true);
      if (checkDMode) {
        setOnlyTimeSlip(false);
        setCommunicationSave(true);
        setOnlyDocs(false);
        setShowSuccess(true);
      }
    }
  }, [clarraDocs]);

  useEffect(() => {
    (async () => {
      const x = getAccessToken();
      if (AccessToken != null) {
		console.log("fetchAllServiceType ");
        dispatch(fetchAllServiceType(AccessToken));
      }
    })();
  }, [AccessToken]);
  useEffect(() => {
    (async () => {
      if (LoginError != null) {
        if (LoginError == "200") {
          SetisValidToken(true);
		  const matterParam = {
			'AccessTokenParam' : AccessToken,
			'NextPage' : 1
		  };
           dispatch(fetchAllMatters(matterParam));
		//   const matterParam1 = {
		// 	'AccessTokenParam' : AccessToken,
		// 	'NextPage' : 2
		//   };
         // dispatch(fetchAllMatters(matterParam1));
          dispatch(fetchAllTags(AccessToken));
          dispatch(fetchAllActivityCode(AccessToken));
          dispatch(fetchAllTaskCode(AccessToken));
          dispatch(fetchAllContacts(matterParam));
          dispatch(fetchAllUsers(AccessToken));
        } else if (LoginError == "401") {
          SetisValidToken(false);
          onCancel();
        }
      }
    })();
  }, [LoginError]);
  const statusOptions = [
    { key: "Sent", text: "Sent" },
    { key: "Receive", text: "Receive" },
  ];
  const handleChange = (field: string) => (event: any, newValue?: any) => {
    const value = Array.isArray(newValue)
      ? newValue
      : event?.target?.type === "checkbox"
      ? event.target.checked
      : newValue;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const validateForm = () => {
    let tempErrors = {
      matter: "",
      tag: "",
      activityCode: "",
      taskCode: "",
      status: "",
      serviceType: "",
      contact: "",
    };
    let isValid = true;

    if (!formData.matter) {
      tempErrors.matter = "Matter is required";
      tempErrors.contact = "Contact is required";
      tempErrors.tag = "Tag is required";
      isValid = false;
    }
    if (formData.tag.length === 0) {
      tempErrors.tag = "At least one tag is required";
      isValid = false;
    }
    if (formData.createClarraDocs && !formData.activityCode) {
      tempErrors.activityCode = "Activity Code is required";
      isValid = false;
    }
    if (formData.createClarraDocs && !formData.taskCode) {
      tempErrors.taskCode = "Task Code is required";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };
  const handleSubmitNew = (EmailData, Mode: any) => {
    // debugger;
    setCheckCMode(Mode.cm);
    setCheckTMode(Mode.tm);
    setCheckDMode(Mode.dm);

    const subject = EmailData.mailItem.subject;
    const externalID = EmailData.mailItem.referenceid;
    const conversationid = EmailData.mailItem.conversationid;
    //ConversationID
    //const body = EmailData.mailItem.body;
    const date = EmailData.mailItem.date;
    const from =
      EmailData.mailItem.senderDisplayName +
      " (" +
      EmailData.mailItem.sender +
      ")";
    const To = EmailData.mailItem.recipents;
    const body = EmailData.mailbody;
    const matter = EmailData?.matter?.label; // + " (" + EmailData?.matter?.value + ")";
    const matterNumber = EmailData?.matter?.value;
    const tags = EmailData?.tag?.map((v: any) => v.label).join(",");
    const time = EmailData.mailItem.time;
    const Token = AccessToken == null ? EmailData.Token : AccessToken;
    let Recipients = [];
    if (To != null) {
      To.forEach((element) => {
        Recipients.push({
          contactID: 0,
          userID: "",
          fullNameWithEmail:
            element.displayName + " (" + element.emailAddress + ")",
          isContact: true,
          type: 1,
        });
      });
    }

    let sender = {
      contactID: 0,
      userID: "",
      fullNameWithEmail: from,
      isContact: true,
      type: 1,
    };
    let PayloadData = {
      filter: Token,
      externalID: externalID,
      conversationID: conversationid,
      id: 0,
      communicationType: "Email",
      Subject: subject,
      date: date,
      time: time,
      description: body,
      Matter: {
        MatterId: matterNumber,
        MatterWithNumber: matter,
      },
      sender: sender,
      recipients: Recipients,
      CommunicationTags: [
        {
          TagID: 0,
          TagName: tags,
        },
      ],

      UploadFileAttachments: EmailData.mailItem.attachments,
      CreateClarraDocs: null,
      ClarraDocsStatus: null,
      ServiceType: null,
      TimeZone: EmailData.mailItem.timezone, // "(UTC-08:00) Pacific Time (US & Canada)",
    };
    SetFiles(EmailData.mailItem.attachments);
    if (!Mode.cm) {
      if (Mode.tm) {
        setCommunicationSave(true);
        setOnlyTimeSlip(true);
      } else {
        if (Mode.dm) {
          setCommunicationSave(true);
          setOnlyTimeSlip(false);
          setOnlyDocs(true);
        } else {
          console.log("You Have to Select one of the Checkbox");
        }
      }
    } else {
      dispatch(SaveCommuncation(PayloadData));
      if (Mode.cm) {
        if (Mode.tm) {
          setCommunicationSave(true);
          setOnlyTimeSlip(true);
        } else if (Mode.dm) {
          setCommunicationSave(true);
          setOnlyDocs(true);
        } else {
          setCommunicationSave(true);
          setShowSuccess(true);
        }
      }
    }
  };
  const handleSubmitNewTimeSlip = (TimeSlipData) => {
   // debugger;

    let PayloadData = {
      externalID: null,
      data: AccessToken == null ? TimeSlipData.Token : AccessToken,
      id: 0,
      date: TimeSlipData.date,
      duration: TimeSlipData.duration,
      timekeeper: TimeSlipData.timekeeper,
      matter: TimeSlipData.matter,
      type: TimeSlipData.activityCode,
      project: TimeSlipData.taskCode,
      description: TimeSlipData.description,
      notes: TimeSlipData.note,
      billable: TimeSlipData.billable,
    };

    if (checkTMode) {
      dispatch(SaveTimeSlip(PayloadData));
      setOnlyTimeSlip(false);
      if (checkDMode) {
        setCommunicationSave(true);
        setOnlyDocs(true);
      } else {
        setCommunicationSave(true);
        setShowSuccess(true);
      }
    }
  };
  const handleSubmitNewClarraDocs = (ClarraDocsData) => {
  //  debugger;
    const matter = ClarraDocsData?.matter?.label;
    const status = ClarraDocsData?.status?.label;
    const serviceType = ClarraDocsData?.serviceType?.label;
    const tags = ClarraDocsData?.tags?.map((v: any) => v.label);
    let PayloadData = {
      externalID: null,
      data: AccessToken == null ? ClarraDocsData.Token : AccessToken,
      id: 0,
      //Files: ClarraDocsData.attachments,
      Note: ClarraDocsData.note,
      MailType: serviceType,
      MatterNumber: matter,
      Date: ClarraDocsData.date,
      Status: status,
      Description: ClarraDocsData.description,
      Tags: tags,
      UploadFileAttachments: ClarraDocsData.attachments,
    };
    if (checkDMode) {
      dispatch(SaveClarraDocs(PayloadData));
      setCommunicationSave(true);
      setOnlyDocs(false);
      setShowSuccess(true);
    }
  };
  return (
    <div style={{ alignItems: "right" }}>
      <div>
        {(loading || timeSlipLoading || clarraDocsLoading) && (
          <div>
            <div>
              {" "}
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1300,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <Loader />
              </Box>
            </div>
          </div>
        )}
        {((!loading && isCommunicationSave) ||
          (!timeSlipLoading && isCommunicationSave) ||
          (!clarraDocsLoading && isCommunicationSave)) && (
          <div>
            <CommunicationStatus mode={mode} show={show} />
          </div>
        )}
        {isValidToken && isCommunicationEnabled && !isCommunicationSave && (
          <EmailLoggingForm
            formData={formData}
            setFormData={setFormData}
            setOnlyTimeSlip={setOnlyTimeSlip}
            createTimeslip={formData.createTimeslip}
            setOnlyDocs={setOnlyDocs}
            createClarraDocs={formData.createClarraDocs}
            setCommunicationSave={setCommunicationSave}
            SetEmailItem={SetEmailItem}
            Reset={true}
            onSubmit={(data, Mode) => {
              setFormData(data);
              handleSubmitNew(data, Mode);
            }}
          />
        )}
        {isValidToken && !isCommunicationEnabled && <Permission />}
        {isValidToken &&
          isCommunicationEnabled &&
          formData &&
          formData.createTimeslip &&
          isOnlyTimeSlip && (
            <LogTimeslipForm
              handleSubmitNewTimeSlip={handleSubmitNewTimeSlip}
              setCommunicationSave={setCommunicationSave}
              setOnlyTimeSlip={setOnlyTimeSlip}
              data={formData}
            />
          )}
        {isValidToken &&
          isCommunicationEnabled &&
          formData &&
          formData.createClarraDocs &&
          isOnlyDocs && (
            <LogClarraDocsForm
              handleSubmitNewClarraDocs={handleSubmitNewClarraDocs}
              ResetFormData={ResetFormData}
              setCommunicationSave={setCommunicationSave}
              setOnlyDocs={setOnlyDocs}
              files={files}
              data={formData}
            />
          )}
        <Status
          showSuccess={showSuccess}
          setCommunicationSave={SetCommunicationMode}
          isOnlyTimeSlip={isOnlyTimeSlip}
          isOnlyDocs={isOnlyDocs}
          isCommunicationSave={isCommunicationSave}
        />
      </div>
    </div>
  );
};

export default BaseComponent;
